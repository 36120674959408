<template>
  <div id="rekapitulasi_mtbs_mtbm">
    <div v-if="!input_data_rekap">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label> Bulan </template>
                    <DatePicker 
                      style="width: 100%"
                      id="mulai_tanggal"
                      type="month"
                      format="MMMM"
                      placeholder="-- Pilih Bulan --"
                      v-model="data_search.tanggal_awal"
                    />
                  </b-form-group>
                  <!-- <pre>{{ data_search.tanggal_awal }}</pre> -->
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label> Tahun </template>
                    <DatePicker 
                      style="width: 100%"
                      id="selesai_tanggal"
                      type="year"
                      format="YYYY"
                      placeholder="-- Pilih Tahun --"
                      v-model="data_search.tanggal_akhir"
                    />
                  </b-form-group>
                  <!-- <pre>{{ data_search.tanggal_akhir }}</pre> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr />
                  <div style="width: 100%; display: flex">
                    <div
                      style="
                        width: 50%;
                        height: 30px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                      "
                    >
                      <h6 style="margin: 0; font-weight: bold">Puskesmas</h6>
                    </div>
                    <div
                      style="
                        width: 50%;
                        height: 30px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_puskesmas"
                          name="check_puskesmas"
                          v-model="checked"
                          @change="toggleSelect()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr />
                  <b-row style="padding-left: 7.5px; margin-top: 15px">
                    <b-col
                      v-for="(item, idx) in options_puskesmas"
                      :key="idx"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="6"
                      xl="6"
                    >
                      <b-form-checkbox v-model="item.checked" @change="addSelect()">
                        {{ item.nama_puskesmas }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">
                        Tampilkan
                      </b-button>
                      <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                      <b-button variant="warning" class="mr-2" @click="resetDataKunjungan">
                        Reset
                      </b-button>
                    </div>

                    <b-button variant="info" @click="inputDataRekap">Input Data</b-button>
                  </div>
                </b-col>
              </b-row>

              <!-- <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr />
                </b-col>
              </b-row> -->

              <b-row class="mt-5">
                <b-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5>
                    <strong>LAPORAN BULANAN - REKAPITULASI MTBS MTBM</strong>
                  </h5>
                  <!-- <p>{{ range_tanggal || "-" }}</p> -->
                  <p>{{ is_data.nama_puskesmas || "-" }} {{ `(${is_data.kode_puskesmas ? is_data.kode_puskesmas : 'kode_puskesmas'})` }}</p>
                  <p>{{ $moment(is_data.bulan_laporan_mtbs, "MM").format("MMMM") || "-" }} - {{ is_data.tahun_laporan_mtbs || "-" }}</p>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <!-- <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Tahun
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.tahun_laporan_mtbs.$model"
                      type="text"
                      plaintext
                      :state="checkIfValid('tahun_laporan_mtbs')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Bulan
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.bulan_laporan_mtbs.$model"
                      type="text"
                      plaintext
                      :state="checkIfValid('bulan_laporan_mtbs')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Kode Puskesmas
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.kode_puskesmas.$model"
                      type="text"
                      plaintext
                      :state="checkIfValid('kode_puskesmas')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Nama Puskesmas
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.nama_puskesmas.$model"
                      type="text"
                      plaintext
                      :state="checkIfValid('nama_puskesmas')"
                    ></b-form-input>
                  </b-form-group> -->
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Jumlah Pustu
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.jumlah_pustu.$model"
                      type="number"
                      plaintext
                      :state="checkIfValid('jumlah_pustu')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Pustu Lapor
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.jumlah_pustu_lapor.$model"
                      type="number"
                      plaintext
                      :state="checkIfValid('jumlah_pustu_lapor')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Jumlah Poskesdes
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.jumlah_poskesdes.$model"
                      type="number"
                      plaintext
                      :state="checkIfValid('jumlah_poskesdes')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Poskesdes Lapor
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="$v.is_data.jumlah_poskesdes_lapor.$model"
                      type="number"
                      plaintext
                      :state="checkIfValid('jumlah_poskesdes_lapor')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <!-- <div class="text-end">Periode : {{ $moment(data_search.bulan_awal).format("LL") }} - {{ $moment(data_search.bulan_akhir).format("LL") }}</div> -->
                  <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                    <b-thead head-variant="dark" class="text-center">
                      <b-tr>
                        <b-th>No.</b-th>
                        <b-th>Kegiatan MTBS / MTBM</b-th>
                        <b-th style="width: 15%">L</b-th>
                        <b-th style="width: 15%">P</b-th>
                        <b-th style="width: 15%">Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-center">1.</b-td>
                        <b-td>Jumlah Bayi <b>Sehat</b> Ke Puskesmas Umur 0 - 2 Bulan</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">2.</b-td>
                        <b-td>Jumlah Bayi <b>Sakit</b> Ke Puskesmas Umur 0 - 2 Bulan</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">3.</b-td>
                        <b-td>Jumlah Bayi <b>Sehat</b> Ke Puskesmas Umur 2 Bulan - 5 Tahun</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">4.</b-td>
                        <b-td>Jumlah Bayi <b>Sakit</b> Ke Puskesmas Umur 2 Bulan - 5 Tahun</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">5.</b-td>
                        <b-td>Jumlah Bayi Balita Mendapat Pelayanan MTBS / MTBM</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>0 Bulan - 2 Bulan (Sehat)</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>0 Bulan - 2 Bulan (Sakit)</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>2 Bulan - 5 Tahun (Sehat)</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>2 Bulan - 5 Tahun (Sakit)</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">6.</b-td>
                        <b-td>Jumlah Kunjungan Sesuai Tempat Tinggal</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>Dalam Wilayah</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center"></b-td>
                        <b-td>
                          <ul class="mb-0 pl-3">
                            <li>Luar Wilayah</li>
                          </ul>
                        </b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.l }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.p }}</b-td>
                        <b-td>{{ is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.total }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                    <b-thead head-variant="dark" class="text-center">
                      <b-tr>
                        <b-th colspan="5">Klasifikasi Umur 0 Bulan - 2 Bulan (MTBS/MTBM)</b-th>
                      </b-tr>
                      <b-tr>
                        <b-td class="warna-rekapitulasi-td">No.</b-td>
                        <b-td class="warna-rekapitulasi-td">Kegiatan MTBS / MTBM</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">L</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">P</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">Total</b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-center">1.</b-td>
                        <b-td>Jumlah Kunjungan Ulang MTBM</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">2.</b-td>
                        <b-td>Penyakit Sangat Berat / Infeksi Bakteri Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">3.</b-td>
                        <b-td>Infeksi Bakteri Lokal</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">4.</b-td>
                        <b-td>Mungkin Bukan Infeksi</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">5.</b-td>
                        <b-td>Ikterus Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">6.</b-td>
                        <b-td>Ikterus</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.ikterus.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">7.</b-td>
                        <b-td>Tidak Ada Ikterus</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">8.</b-td>
                        <b-td>Diare Dehidrasi Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">9.</b-td>
                        <b-td>Diare Dehidrasi Ringan / Sedang</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">10.</b-td>
                        <b-td>Diare Tanpa Dehidrasi</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">11.</b-td>
                        <b-td>Infeksi HIV Terkonfirmasi</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">12.</b-td>
                        <b-td>Terpajan HIV</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">13.</b-td>
                        <b-td>Mungkin Bukan Infeksi HIV</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">14.</b-td>
                        <b-td>BB Rendah Menurut Umur</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">15.</b-td>
                        <b-td>Masalah Pemberian ASI</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">16.</b-td>
                        <b-td>BB Rendah Menurut Umur & Masalah Pemberian ASI</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">17.</b-td>
                        <b-td>BB Tidak Rendah Menurut Umur & Tidak ada Masalah Pemberian ASI</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">18.</b-td>
                        <b-td>Masalah Keluhan Lain</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">19.</b-td>
                        <b-td>Rujukan</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.rujukan.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.rujukan.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_0_bulan_2_bulan.rujukan.total }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              
              <b-row class="mt-3">
                <b-col>
                  <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                    <b-thead head-variant="dark" class="text-center">
                      <b-tr>
                        <b-th colspan="5">Klasifikasi Umur 2 Bulan - 5 Tahun (MTBS)</b-th>
                      </b-tr>
                      <b-tr>
                        <b-td class="warna-rekapitulasi-td">No.</b-td>
                        <b-td class="warna-rekapitulasi-td">Kegiatan MTBS / MTBM</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">L</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">P</b-td>
                        <b-td class="warna-rekapitulasi-td" style="width: 15%">Total</b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-center">1.</b-td>
                        <b-td>Jumlah Kunjungan Ulang MTBS</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">2.</b-td>
                        <b-td>Penyakit Sangat Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">3.</b-td>
                        <b-td>Pneumonia Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">4.</b-td>
                        <b-td>Pneumonia</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.pneumonia.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">5.</b-td>
                        <b-td>Batuk Bukan Pneumonia</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">6.</b-td>
                        <b-td>Diare Dehidrasi Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">7.</b-td>
                        <b-td>Diare Dehidrasi Ringan / Sedang</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">8.</b-td>
                        <b-td>Diare Tanpa Dehidrasi</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">9.</b-td>
                        <b-td>Diare Persisten Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">10.</b-td>
                        <b-td>Diare Persisten</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">11.</b-td>
                        <b-td>Disentri</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.disentri.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.disentri.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.disentri.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">12.</b-td>
                        <b-td>Penyakit Berat Dengan Demam</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">13.</b-td>
                        <b-td>Malaria</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.malaria.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.malaria.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.malaria.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">14.</b-td>
                        <b-td>Demam Mungkin Bukan Malaria</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">15.</b-td>
                        <b-td>Demam Bukan Malaria</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">16.</b-td>
                        <b-td>Campak Dengan Komplikasi Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">17.</b-td>
                        <b-td>Campak Dengan Komplikasi Pada Mulut</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">18.</b-td>
                        <b-td>Campak Dengan Komplikasi Pada Mata</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">19.</b-td>
                        <b-td>Campak Dengan Komplikasi Pada Mata Dan Mulut</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">20.</b-td>
                        <b-td>Campak</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.campak.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">21.</b-td>
                        <b-td>Demam Berdarah Dengue</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">22.</b-td>
                        <b-td>Mungkin DBD</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">23.</b-td>
                        <b-td>Demam Mungkin Bukan DBD</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">24.</b-td>
                        <b-td>Mastoiditis</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">25.</b-td>
                        <b-td>Infeksi Telinga Akut</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">26.</b-td>
                        <b-td>Infeksi Telinga Kronis</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">27.</b-td>
                        <b-td>Tidak Ada Infeksi Telinga</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">28.</b-td>
                        <b-td>Gizi Buruk Dengan Komplikasi</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">29.</b-td>
                        <b-td>Gizi Buruk Tanpa Komplikasi</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">30.</b-td>
                        <b-td>Gizi Kurang</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">31.</b-td>
                        <b-td>Gizi Baik</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">32.</b-td>
                        <b-td>Anemia Berat</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">33.</b-td>
                        <b-td>Anemia</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.anemia.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">34.</b-td>
                        <b-td>Tidak Anemia</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">35.</b-td>
                        <b-td>Infeksi HIV Terkonfirmasi</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">36.</b-td>
                        <b-td>Diduga Terinfeksi HIV</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">37.</b-td>
                        <b-td>Terpajan HIV</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">38.</b-td>
                        <b-td>Kemungkinan Bukan Infeksi HIV</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">39.</b-td>
                        <b-td>Masalah Keluhan Lain</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.total }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="text-center">40.</b-td>
                        <b-td>Jumlah Rujukan</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.l }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.p }}</b-td>
                        <b-td>{{ is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.total }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <InputRekapitulasiMtbsMtbm :data_rekap="is_data" :laporan_mtbs_id="is_data.laporan_mtbs_id" @emitInputDataRekap="inputDataRekap()" />
    </div>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import {  } from "vuelidate/lib/validators";

import InputRekapitulasiMtbsMtbm from "./input_rekapitulasi_mtbs_mtbm.vue";

export default {
  name: "kunjungan_pasien",
  components: {
    InputRekapitulasiMtbsMtbm,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      options_kecamatan: [],
      options_kelurahan: [],
      options_diagnosa: [],
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        bulan_awal: null,
        bulan_akhir: null,
        tahun_awal: "",
        tahun_akhir: "",
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      },
      is_data: {
        tahun_laporan_mtbs: 0 || Number(this.$moment().format("YYYY")),
        bulan_laporan_mtbs: 0 || Number(this.$moment().format("MM")),
        kegiatan_laporan_mtbm: {
          jumlah_bayi_sehat_0_2_bulan: {
            l: 3,
            p: 8,
            total: 11
          },
          jumlah_bayi_sakit_0_2_bulan: {
            l: 18,
            p: 31,
            total: 49
          },
          jumlah_bayi_sehat_2_bulan_5_tahun: {
            l: 5,
            p: 15,
            total: 20
          },
          jumlah_bayi_sakit_2_bulan_5_tahun: {
            l: 9,
            p: 9,
            total: 18
          },
          jumlah_bayi_mendapat_mtbs_mtbm: {
            l: 7,
            p: 9,
            total: 16
          },
          jumlah_0_2_bulan_sehat: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_0_2_bulan_sakit: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_2_5_bulan_sehat: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_2_5_bulan_sakit: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_sesuai_tempat: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_dalam_wilayah: {
            l: 1,
            p: 2,
            total: 3
          },
          jumlah_luar_wilayah: {
            l: 1,
            p: 2,
            total: 3
          },
        },
        klasifikasi_0_bulan_2_bulan: {
          jumlah_kunjungan_ulang: {
            l: 1,
            p: 2,
            total: 3
          },
          penyakit_infeksi_berat: {
            l: 1,
            p: 2,
            total: 3
          },
          infeksi_bakteri_lokal: {
            l: 1,
            p: 2,
            total: 3
          },
          mungkin_bukan_infeksi: {
            l: 1,
            p: 2,
            total: 3
          },
          ikterus_berat: {
            l: 1,
            p: 2,
            total: 3
          },
          ikterus: {
            l: 1,
            p: 2,
            total: 3
          },
          tidak_ada_ikterus: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_dehidrasi_berat: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_dehidrasi_ringan: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_tanpa_dehidrasi: {
            l: 4,
            p: 5,
            total: 9
          },
          infeksi_hiv_terkonfirmasi: {
            l: 4,
            p: 5,
            total: 9
          },
          terpajan_hiv: {
            l: 4,
            p: 5,
            total: 9
          },
          mungkin_bukan_hiv: {
            l: 4,
            p: 5,
            total: 9
          },
          bb_rendah_menurut_umur: {
            l: 10,
            p: 11,
            total: 21
          },
          masalah_pemberian_asi: {
            l: 13,
            p: 5,
            total: 18
          },
          bb_rendah_masalah_pemberian_asi: {
            l: 17,
            p: 18,
            total: 40
          },
          bb_rendah_tidak_masalah_pemberian_asi: {
            l: 4,
            p: 5,
            total: 9
          },
          masalah_keluhan_lain: {
            l: 4,
            p: 5,
            total: 9
          },
          rujukan: {
            l: 4,
            p: 5,
            total: 9
          },
        },
        klasifikasi_2_bulan_5_tahun: {
          jumlah_kunjungan_ulang: {
            l: 1,
            p: 2,
            total: 3
          },
          penyakit_sangat_berat: {
            l: 1,
            p: 2,
            total: 3
          },
          pneumonia_berat: {
            l: 1,
            p: 2,
            total: 3
          },
          pneumonia: {
            l: 1,
            p: 2,
            total: 3
          },
          batuk_bukan_pneumonia: {
            l: 1,
            p: 2,
            total: 3
          },
          diare_dehidrasi_berat: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_dehidrasi_ringan: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_tanpa_dehidrasi: {
            l: 4,
            p: 5,
            total: 9
          },
          diare_persisten_berat: {
            l: 1,
            p: 2,
            total: 3
          },
          diare_persisten: {
            l: 4,
            p: 5,
            total: 9
          },
          disentri: {
            l: 4,
            p: 5,
            total: 9
          },
          penyakit_berat_dengan_demam: {
            l: 4,
            p: 5,
            total: 9
          },
          malaria: {
            l: 4,
            p: 5,
            total: 9
          },
          demam_mungkin_bukan_malaria: {
            l: 4,
            p: 5,
            total: 9
          },
          demam_bukan_malaria: {
            l: 4,
            p: 5,
            total: 9
          },
          campak_dengan_komplikasi_berat: {
            l: 21,
            p: 33,
            total: 54
          },
          campak_dengan_komplikasi_pada_mulut: {
            l: 21,
            p: 33,
            total: 54
          },
          campak_dengan_komplikasi_pada_mata: {
            l: 21,
            p: 33,
            total: 54
          },
          campak_dengan_komplikasi_mulut_mata: {
            l: 21,
            p: 33,
            total: 54
          },
          campak: {
            l: 21,
            p: 33,
            total: 54
          },
          demam_berdarah_dengue: {
            l: 21,
            p: 33,
            total: 54
          },
          mungkin_dbd: {
            l: 21,
            p: 33,
            total: 54
          },
          demam_mungkin_bukan_dbd: {
            l: 21,
            p: 33,
            total: 54
          },
          mastoiditis: {
            l: 4,
            p: 5,
            total: 9
          },
          infeksi_telinga_akut: {
            l: 4,
            p: 5,
            total: 9
          },
          infeksi_telinga_kronis: {
            l: 4,
            p: 5,
            total: 9
          },
          tidak_ada_infeksi_telinga: {
            l: 10,
            p: 11,
            total: 21
          },
          gizi_buruk_dengan_komplikasi: {
            l: 13,
            p: 5,
            total: 18
          },
          gizi_buruk_tanpa_komplikasi: {
            l: 17,
            p: 18,
            total: 40
          },
          gizi_kurang: {
            l: 4,
            p: 5,
            total: 9
          },
          gizi_baik: {
            l: 4,
            p: 5,
            total: 9
          },
          anemia_berat: {
            l: 4,
            p: 5,
            total: 9
          },
          anemia: {
            l: 4,
            p: 5,
            total: 9
          },
          tidak_anemia: {
            l: 4,
            p: 5,
            total: 9
          },
          infeksi_hiv_terkonfirmasi: {
            l: 4,
            p: 5,
            total: 9
          },
          diduga_terinfeksi_hiv: {
            l: 4,
            p: 5,
            total: 9
          },
          terpajan_hiv: {
            l: 17,
            p: 18,
            total: 40
          },
          kemungkinan_bukan_infeksi_hiv: {
            l: 17,
            p: 18,
            total: 40
          },
          masalah_keluhan_lain: {
            l: 17,
            p: 18,
            total: 40
          },
          jumlah_rujukan: {
            l: 17,
            p: 18,
            total: 40
          },
        },
        jumlah_pustu: 0,
        jumlah_pustu_lapor: 0,
        jumlah_poskesdes: 0,
        jumlah_poskesdes_lapor: 0,

        nama_puskesmas: "",
        kode_puskesmas: "",
      },
      items: [],
      range_tanggal: "",
      sub_total_harga: 0,

      checked: false,
      checked_poli: "",
      select_puskesmas: [],
      select_poli: [],
      options_puskesmas: [],
      options_poli: [],

      optionBulan: [
        { text: "-- pilih bulan --", value: null },
        { text: "Januari", value: "Januari" },
        { text: "Februari", value: "Februari" },
        { text: "Maret", value: "Maret" },
        { text: "April", value: "April" },
        { text: "Mei", value: "Mei" },
        { text: "Juni", value: "Juni" },
        { text: "Juli", value: "Juli" },
        { text: "Agustus", value: "Agustus" },
        { text: "September", value: "September" },
        { text: "Oktober", value: "Oktober" },
        { text: "November", value: "November" },
        { text: "Desember", value: "Desember" },
      ],
      optionTahun: [
        { text: "-- pilih tuhan --", value: null },
        { text: "2021", value: "2021" },
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
      ],
      optionResiko: [
        { text: "pasien", value: "pasien" },
        { text: "keluarga", value: "keluarga" },
        { text: "masyarakat", value: "masyarakat" },
        { text: "dukun", value: "dukun" },
        { text: "kader", value: "kader" },
        { text: "bidan", value: "bidan" },
        { text: "perawat", value: "perawat" },
        { text: "dokter", value: "dokter" },
      ],
      optionKomplikasi: [
        { text: "dsog", value: "dsog" },
        { text: "hdk", value: "hdk" },
        { text: "abortus", value: "abortus" },
        { text: "pendarahan", value: "pendarahan" },
        { text: "infeksi", value: "infeksi" },
        { text: "kpd", value: "kpd" },
        { text: "lainnya", value: "lainnya" },
        { text: "dokter", value: "dokter" },
      ],
      optionRujukan: [
        { text: "puskesmas", value: "puskesmas" },
        { text: "klinik", value: "klinik" },
        { text: "rb", value: "rb" },
        { text: "rsia", value: "rsia" },
        { text: "rs", value: "rs" },
        { text: "lainnya", value: "lainnya" },
      ],

      input_data_rekap: false,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  activated() {
    this.data_search.tanggal_awal = new Date();
    this.data_search.tanggal_akhir = new Date();
    this.getData();
    this.getDataKunjungan();
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      tahun_laporan_mtbs: {},
      bulan_laporan_mtbs: {},
      kegiatan_laporan_mtbm: {},
      klasifikasi_2_bulan_5_tahun: {},
      klasifikasi_0_bulan_2_bulan: {},
      jumlah_pustu: {},
      jumlah_pustu_lapor: {},
      jumlah_poskesdes: {},
      jumlah_poskesdes_lapor: {},

      nama_puskesmas: {},
      kode_puskesmas: {},
    },
  },
  mounted() {
    this.is_data.nama_puskesmas = this.$store.state.puskesmas.nama_puskesmas;
    this.is_data.kode_puskesmas = this.$store.state.puskesmas.kode_puskesmas;
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    inputDataRekap() {
      this.input_data_rekap = !this.input_data_rekap;
      if (!this.input_data_rekap) {
        // console.log("ini false");
        this.getDataKunjungan()
      }
    },
    async getData() {
      let vm = this;
      let _puskesmas = { nama_puskesmas: vm.$store.state.puskesmas.nama_puskesmas };
      let puskesmas = await vm.$axios.post("/puskesmas/list", _puskesmas);
      if (puskesmas.data.data.length) {
        vm.options_puskesmas = puskesmas.data.data;
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
          let x = vm.options_puskesmas[i];
          x.checked = false;
        }
      }
      let _poli = { nama_poli: "kia" };
      let poli = await vm.$axios.post("/ms_poli/list", _poli);
      if (poli.data.data.length) {
        vm.options_poli = poli.data.data;
        for (let i = 0; i < vm.options_poli.length; i++) {
          let x = vm.options_poli[i];
          x.checked = false;
        }
      }
    },
    getDataKunjungan() {
      let vm = this;
      if (vm.select_puskesmas.length) {
        vm.data_search.bulk_puskesmas_id = vm.select_puskesmas;
      }
      if (vm.select_poli.length) {
        vm.data_search.bulk_ms_poli_id = vm.select_poli;
      }
      vm.resetIsData()

      vm.data_search.bulan_awal = vm.$moment(vm.data_search.tanggal_awal).format("MM");
      vm.data_search.tahun_awal = vm.$moment(vm.data_search.tanggal_awal).format("YYYY");
      vm.data_search.bulan_akhir = vm.$moment(vm.data_search.tanggal_akhir).format("MM");
      vm.data_search.tahun_akhir = vm.$moment(vm.data_search.tanggal_akhir).format("YYYY");
      // console.log("data search", vm.data_search);
      vm.data_search.bulan_laporan_mtbs = vm.data_search.bulan_awal
      vm.data_search.tahun_laporan_mtbs = vm.data_search.tahun_akhir

      vm.range_tanggal = `${vm.$moment(vm.data_search.tanggal_awal).format("MMMM YYYY")} - ${vm.$moment(vm.data_search.tanggal_akhir).format("MMMM YYYY")}`;
      vm.$axios
        .post("/laporan_mtbs/list", vm.data_search)
        .then((res) => {
          // console.log(res, "ini respon");
          if (res.data.status == 200) {
            let x = res.data.data;
            vm.items = x;
            if (x.length) {
              vm.is_data = x[0]
            } else {
              vm.is_data.bulan_laporan_mtbs = vm.data_search.bulan_awal
              vm.is_data.tahun_laporan_mtbs = vm.data_search.tahun_akhir
              vm.is_data.nama_puskesmas = vm.$store.state.puskesmas.nama_puskesmas;
              vm.is_data.kode_puskesmas = vm.$store.state.puskesmas.kode_puskesmas;
            }
            // console.log(">>>>>>>>>>>", vm.is_data);
          } else {
            vm.items = [];
            vm.$store.commit("set_alert", {
              msg: "TIDAK ADA DATA",
              variant: "danger",
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$store.commit("set_alert", {
            msg: err.message,
            variant: "danger",
            showing: true,
          });
        });
    },
    toggleSelect: function () {
      let select = this.checked;
      this.options_puskesmas.forEach(function (data) {
        data.checked = select;
      });
      this.select_puskesmas = [];
      for (let i = 0; i < this.options_puskesmas.length; i++) {
        let x = this.options_puskesmas[i];
        if (x.checked == true) {
          this.select_puskesmas.push(x.id);
        }
      }
      this.$forceUpdate();
    },
    toggleSelectPoli: function () {
      let select = this.checked_poli;
      // console.log(this.checked_poli);
      this.options_poli.forEach(function (data) {
        data.checked = select;
      });
      this.select_poli = [];
      for (let i = 0; i < this.options_poli.length; i++) {
        let x = this.options_poli[i];
        if (x.checked == true) {
          this.select_poli.push(x.ms_poli_id);
        }
      }
      this.$forceUpdate();
    },
    addSelect() {
      let vm = this;
      vm.select_puskesmas = [];
      for (let i = 0; i < vm.options_puskesmas.length; i++) {
        let x = vm.options_puskesmas[i];
        if (x.checked == true) {
          vm.select_puskesmas.push(x.id);
        }
      }
    },
    addSelectPoli() {
      let vm = this;
      vm.select_poli = [];
      for (let i = 0; i < vm.options_poli.length; i++) {
        let x = vm.options_poli[i];
        if (x.checked == true) {
          vm.select_poli.push(x.ms_poli_id);
        }
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
    resetDataKunjungan() {
      this.data_search = {
        tanggal_awal: new Date(),
        tanggal_akhir: new Date(),
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      };
      this.select_puskesmas = [];
      this.select_poli = [];
      this.getData();
      this.getDataKunjungan();
    },
    resetIsData() {
      this.is_data = {
        tahun_laporan_mtbs: 0 || Number(this.$moment().format("YYYY")),
        bulan_laporan_mtbs: 0 || Number(this.$moment().format("MM")),
        kegiatan_laporan_mtbm: {
          jumlah_bayi_sehat_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sehat_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_mendapat_mtbs_mtbm: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_sesuai_tempat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_dalam_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_luar_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        klasifikasi_0_bulan_2_bulan: {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_infeksi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_bakteri_lokal: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_infeksi: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_menurut_umur: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_tidak_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        klasifikasi_2_bulan_5_tahun: {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_sangat_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          batuk_bukan_pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten: {
            l: 0,
            p: 0,
            total: 0
          },
          disentri: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_berat_dengan_demam: {
            l: 0,
            p: 0,
            total: 0
          },
          malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mulut: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_mulut_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_berdarah_dengue: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          mastoiditis: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_akut: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_kronis: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_infeksi_telinga: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_dengan_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_tanpa_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_kurang: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_baik: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diduga_terinfeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          kemungkinan_bukan_infeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        jumlah_pustu: 0,
        jumlah_pustu_lapor: 0,
        jumlah_poskesdes: 0,
        jumlah_poskesdes_lapor: 0,

        nama_puskesmas: "",
        kode_puskesmas: "",
      }
    },
    setTanggal() {
      this.data_search.tanggal_awal = new Date();
      this.data_search.tanggal_akhir = new Date();
    },
  },
};
</script>

<style>
/* #kohort-kia .custom-table td {
  padding: 0 !important;
  font-size: 14px;
  font-weight: bold;
  border: hidden !important;
}

#kohort-kia .custom-control-label {
  padding-top: 2.5px;
}
.top-text-variant {
  font-size: 10px;
} */
#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th {
  background-color: #D52F65 !important ;
  color: #fff !important;
}
#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th:first-child {
  border-top-left-radius: 8px;
}
#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th:last-child {
  border-top-right-radius: 8px;
}
.warna-rekapitulasi-td {
  background-color: #D52F65 !important ;
  color: #fff !important;
  font-weight: 700
}
</style>
