<template>
  <b-container fluid id="rekapitulasi_mtbs_mtbm">
    <!-- <b-row>
      <b-col>
        <pre>{{ data_rekap }}</pre>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <b-card>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div style="display: flex; justify-content: flex-end">
                <b-button variant="info" @click="$emit('emitInputDataRekap')">
                  Lihat Data
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
              <h5>
                <strong>LAPORAN BULANAN - REKAPITULASI MTBS MTBM</strong>
              </h5>
              <!-- <p>{{ range_tanggal || "-" }}</p> -->
              <!-- <p>
                {{ is_data.nama_puskesmas || "-" }}
                {{ `(${is_data.kode_puskesmas ? is_data.kode_puskesmas : "kode_puskesmas"})` }}
              </p> -->
              <!-- <p>
                {{ $moment(is_data.bulan_laporan_mtbs, "MM").format("MMMM") || "-" }} -
                {{ is_data.tahun_laporan_mtbs || "-" }}
              </p> -->
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Tahun
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.tahun_laporan_mtbs.$model"
                  type="number"
                  :state="checkIfValid('tahun_laporan_mtbs')"
                  @change="getDataKunjungan()"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Nama Puskesmas
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.nama_puskesmas.$model"
                  type="text"
                  readonly
                  :state="checkIfValid('nama_puskesmas')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Jumlah Pustu
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.jumlah_pustu.$model"
                  type="number"
                  :state="checkIfValid('jumlah_pustu')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Pustu Lapor
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.jumlah_pustu_lapor.$model"
                  type="number"
                  :state="checkIfValid('jumlah_pustu_lapor')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Bulan
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  v-model="$v.is_data.bulan_laporan_mtbs.$model"
                  :options="optionBulan"
                  :state="checkIfValid('jumlah_pustu_lapor')"
                  @change="getDataKunjungan()"
                ></b-form-select>
                <!-- <b-input-group>
                  <b-form-input
                    v-model="$v.is_data.bulan_laporan_mtbs.$model"
                    type="number"
                    :state="checkIfValid('bulan_laporan_mtbs')"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button disabled>{{ getBulan }}</b-button>
                  </b-input-group-append>
                </b-input-group> -->
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Kode Puskesmas
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.kode_puskesmas.$model"
                  type="text"
                  readonly
                  :state="checkIfValid('kode_puskesmas')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Jumlah Poskesdes
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.jumlah_poskesdes.$model"
                  type="number"
                  :state="checkIfValid('jumlah_poskesdes')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Poskesdes Lapor
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.is_data.jumlah_poskesdes_lapor.$model"
                  type="number"
                  :state="checkIfValid('jumlah_poskesdes_lapor')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <!-- <div class="text-end">Periode : {{ $moment(data_search.bulan_awal).format("LL") }} - {{ $moment(data_search.bulan_akhir).format("LL") }}</div> -->
              <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                <b-thead head-variant="dark" class="text-center">
                  <b-tr>
                    <b-th>No.</b-th>
                    <b-th>Kegiatan MTBS / MTBM</b-th>
                    <b-th style="width: 15%">L</b-th>
                    <b-th style="width: 15%">P</b-th>
                    <b-th style="width: 15%">Total</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="text-center">1.</b-td>
                    <b-td>Jumlah Bayi <b>Sehat</b> Ke Puskesmas Umur 0 - 2 Bulan</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sehat_0_2_bulan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sehat_0_2_bulan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_0_2_bulan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">2.</b-td>
                    <b-td>Jumlah Bayi <b>Sakit</b> Ke Puskesmas Umur 0 - 2 Bulan</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sakit_0_2_bulan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sakit_0_2_bulan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_0_2_bulan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">3.</b-td>
                    <b-td>Jumlah Bayi <b>Sehat</b> Ke Puskesmas Umur 2 Bulan - 5 Tahun</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sehat_2_bulan_5_tahun')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sehat_2_bulan_5_tahun')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sehat_2_bulan_5_tahun.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">4.</b-td>
                    <b-td>Jumlah Bayi <b>Sakit</b> Ke Puskesmas Umur 2 Bulan - 5 Tahun</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sakit_2_bulan_5_tahun')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_bayi_sakit_2_bulan_5_tahun')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_sakit_2_bulan_5_tahun.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">5.</b-td>
                    <b-td>Jumlah Bayi Balita Mendapat Pelayanan MTBS / MTBM</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.l"
                        type="number"
                        readonly
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.p"
                        type="number"
                        readonly
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_bayi_mendapat_mtbs_mtbm.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>0 Bulan - 2 Bulan (Sehat)</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_0_2_bulan_sehat'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_0_2_bulan_sehat'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sehat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>0 Bulan - 2 Bulan (Sakit)</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_0_2_bulan_sakit'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_0_2_bulan_sakit'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_0_2_bulan_sakit.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>2 Bulan - 5 Tahun (Sehat)</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_2_5_bulan_sehat'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_2_5_bulan_sehat'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sehat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>2 Bulan - 5 Tahun (Sakit)</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_2_5_bulan_sakit'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_2_5_bulan_sakit'), totalBayiPelayananMTBS()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_2_5_bulan_sakit.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">6.</b-td>
                    <b-td>Jumlah Kunjungan Sesuai Tempat Tinggal</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.l"
                        type="number"
                        readonly
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.p"
                        type="number"
                        readonly
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_sesuai_tempat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>Dalam Wilayah</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_dalam_wilayah'), totalKunjungan()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_dalam_wilayah'), totalKunjungan()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_dalam_wilayah.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center"></b-td>
                    <b-td>
                      <ul class="mb-0 pl-3">
                        <li>Luar Wilayah</li>
                      </ul>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.l"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_luar_wilayah'), totalKunjungan()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.p"
                        type="number"
                        @input="hitungTotal('kegiatan_laporan_mtbm', 'jumlah_luar_wilayah'), totalKunjungan()"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.kegiatan_laporan_mtbm.jumlah_luar_wilayah.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col>
              <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                <b-thead head-variant="dark" class="text-center">
                  <b-tr>
                    <b-th colspan="5">Klasifikasi Umur 0 Bulan - 2 Bulan (MTBS/MTBM)</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td class="warna-rekapitulasi-td">No.</b-td>
                    <b-td class="warna-rekapitulasi-td">Kegiatan MTBS / MTBM</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">L</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">P</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">Total</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="text-center">1.</b-td>
                    <b-td>Jumlah Kunjungan Ulang MTBM</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'jumlah_kunjungan_ulang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'jumlah_kunjungan_ulang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.jumlah_kunjungan_ulang.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">2.</b-td>
                    <b-td>Penyakit Sangat Berat / Infeksi Bakteri Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'penyakit_infeksi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'penyakit_infeksi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.penyakit_infeksi_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">3.</b-td>
                    <b-td>Infeksi Bakteri Lokal</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'infeksi_bakteri_lokal')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'infeksi_bakteri_lokal')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_bakteri_lokal.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">4.</b-td>
                    <b-td>Mungkin Bukan Infeksi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'mungkin_bukan_infeksi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'mungkin_bukan_infeksi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_infeksi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">5.</b-td>
                    <b-td>Ikterus Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'ikterus_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'ikterus_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">6.</b-td>
                    <b-td>Ikterus</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'ikterus')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'ikterus')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.ikterus.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">7.</b-td>
                    <b-td>Tidak Ada Ikterus</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'tidak_ada_ikterus')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'tidak_ada_ikterus')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.tidak_ada_ikterus.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">8.</b-td>
                    <b-td>Diare Dehidrasi Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_dehidrasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_dehidrasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">9.</b-td>
                    <b-td>Diare Dehidrasi Ringan / Sedang</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_dehidrasi_ringan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_dehidrasi_ringan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_dehidrasi_ringan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">10.</b-td>
                    <b-td>Diare Tanpa Dehidrasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_tanpa_dehidrasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'diare_tanpa_dehidrasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.diare_tanpa_dehidrasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">11.</b-td>
                    <b-td>Infeksi HIV Terkonfirmasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'infeksi_hiv_terkonfirmasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'infeksi_hiv_terkonfirmasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.infeksi_hiv_terkonfirmasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">12.</b-td>
                    <b-td>Terpajan HIV</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'terpajan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'terpajan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.terpajan_hiv.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">13.</b-td>
                    <b-td>Mungkin Bukan Infeksi HIV</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'mungkin_bukan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'mungkin_bukan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.mungkin_bukan_hiv.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">14.</b-td>
                    <b-td>BB Rendah Menurut Umur</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_menurut_umur')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_menurut_umur')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_menurut_umur.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">15.</b-td>
                    <b-td>Masalah Pemberian ASI</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_pemberian_asi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">16.</b-td>
                    <b-td>BB Rendah Menurut Umur & Masalah Pemberian ASI</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_masalah_pemberian_asi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">17.</b-td>
                    <b-td>BB Tidak Rendah Menurut Umur & Tidak ada Masalah Pemberian ASI</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_tidak_masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'bb_rendah_tidak_masalah_pemberian_asi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.bb_rendah_tidak_masalah_pemberian_asi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">0.</b-td>
                    <b-td>Masalah Keluhan Lain</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'masalah_keluhan_lain')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'masalah_keluhan_lain')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.masalah_keluhan_lain.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">19.</b-td>
                    <b-td>Rujukan</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.rujukan.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'rujukan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.rujukan.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_0_bulan_2_bulan', 'rujukan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_0_bulan_2_bulan.rujukan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
                        
          <b-row class="mt-3">
            <b-col>
              <b-table-simple bordered responsive hover table-class="warna-rekapitulasi-mtbs">
                <b-thead head-variant="dark" class="text-center">
                  <b-tr>
                    <b-th colspan="5">Klasifikasi Umur 2 Bulan - 5 Tahun (MTBS)</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td class="warna-rekapitulasi-td">No.</b-td>
                    <b-td class="warna-rekapitulasi-td">Kegiatan MTBS / MTBM</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">L</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">P</b-td>
                    <b-td class="warna-rekapitulasi-td" style="width: 15%">Total</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="text-center">1.</b-td>
                    <b-td>Jumlah Kunjungan Ulang MTBS</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'jumlah_kunjungan_ulang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'jumlah_kunjungan_ulang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_kunjungan_ulang.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">2.</b-td>
                    <b-td>Penyakit Sangat Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'penyakit_sangat_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'penyakit_sangat_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_sangat_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">3.</b-td>
                    <b-td>Pneumonia Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'pneumonia_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'pneumonia_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">4.</b-td>
                    <b-td>Pneumonia</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'pneumonia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'pneumonia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.pneumonia.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">5.</b-td>
                    <b-td>Batuk Bukan Pneumonia</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'batuk_bukan_pneumonia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'batuk_bukan_pneumonia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.batuk_bukan_pneumonia.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">6.</b-td>
                    <b-td>Diare Dehidrasi Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_dehidrasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_dehidrasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">7.</b-td>
                    <b-td>Diare Dehidrasi Ringan / Sedang</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_dehidrasi_ringan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_dehidrasi_ringan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_dehidrasi_ringan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">8.</b-td>
                    <b-td>Diare Tanpa Dehidrasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_tanpa_dehidrasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_tanpa_dehidrasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_tanpa_dehidrasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">9.</b-td>
                    <b-td>Diare Persisten Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_persisten_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_persisten_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">10.</b-td>
                    <b-td>Diare Persisten</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_persisten')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diare_persisten')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diare_persisten.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">11.</b-td>
                    <b-td>Disentri</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.disentri.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'disentri')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.disentri.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'disentri')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.disentri.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">12.</b-td>
                    <b-td>Penyakit Berat Dengan Demam</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'penyakit_berat_dengan_demam')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'penyakit_berat_dengan_demam')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.penyakit_berat_dengan_demam.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">13.</b-td>
                    <b-td>Malaria</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.malaria.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.malaria.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.malaria.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">14.</b-td>
                    <b-td>Demam Mungkin Bukan Malaria</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_mungkin_bukan_malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_mungkin_bukan_malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_malaria.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">15.</b-td>
                    <b-td>Demam Bukan Malaria</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_bukan_malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_bukan_malaria')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_bukan_malaria.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">16.</b-td>
                    <b-td>Campak Dengan Komplikasi Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">17.</b-td>
                    <b-td>Campak Dengan Komplikasi Pada Mulut</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_pada_mulut')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_pada_mulut')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mulut.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">0.</b-td>
                    <b-td>Campak Dengan Komplikasi Pada Mata</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_pada_mata')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_pada_mata')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_pada_mata.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">19.</b-td>
                    <b-td>Campak Dengan Komplikasi Pada Mata Dan Mulut</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_mulut_mata')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak_dengan_komplikasi_mulut_mata')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak_dengan_komplikasi_mulut_mata.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">20.</b-td>
                    <b-td>Campak</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'campak')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.campak.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">21.</b-td>
                    <b-td>Demam Berdarah Dengue</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_berdarah_dengue')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_berdarah_dengue')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_berdarah_dengue.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">22.</b-td>
                    <b-td>Mungkin DBD</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'mungkin_dbd')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'mungkin_dbd')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mungkin_dbd.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">23.</b-td>
                    <b-td>Demam Mungkin Bukan DBD</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_mungkin_bukan_dbd')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'demam_mungkin_bukan_dbd')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.demam_mungkin_bukan_dbd.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">24.</b-td>
                    <b-td>Mastoiditis</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'mastoiditis')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'mastoiditis')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.mastoiditis.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">25.</b-td>
                    <b-td>Infeksi Telinga Akut</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_telinga_akut')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_telinga_akut')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_akut.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">26.</b-td>
                    <b-td>Infeksi Telinga Kronis</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_telinga_kronis')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_telinga_kronis')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_telinga_kronis.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">27.</b-td>
                    <b-td>Tidak Ada Infeksi Telinga</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'tidak_ada_infeksi_telinga')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'tidak_ada_infeksi_telinga')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_ada_infeksi_telinga.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">28.</b-td>
                    <b-td>Gizi Buruk Dengan Komplikasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_buruk_dengan_komplikasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_buruk_dengan_komplikasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_dengan_komplikasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">29.</b-td>
                    <b-td>Gizi Buruk Tanpa Komplikasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_buruk_tanpa_komplikasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_buruk_tanpa_komplikasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_buruk_tanpa_komplikasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">30.</b-td>
                    <b-td>Gizi Kurang</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_kurang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_kurang')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_kurang.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">31.</b-td>
                    <b-td>Gizi Baik</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_baik')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'gizi_baik')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.gizi_baik.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">32.</b-td>
                    <b-td>Anemia Berat</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'anemia_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'anemia_berat')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia_berat.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">33.</b-td>
                    <b-td>Anemia</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'anemia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'anemia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.anemia.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">34.</b-td>
                    <b-td>Tidak Anemia</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'tidak_anemia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'tidak_anemia')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.tidak_anemia.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">35.</b-td>
                    <b-td>Infeksi HIV Terkonfirmasi</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_hiv_terkonfirmasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'infeksi_hiv_terkonfirmasi')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.infeksi_hiv_terkonfirmasi.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">36.</b-td>
                    <b-td>Diduga Terinfeksi HIV</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diduga_terinfeksi_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'diduga_terinfeksi_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.diduga_terinfeksi_hiv.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">37.</b-td>
                    <b-td>Terpajan HIV</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'terpajan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'terpajan_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.terpajan_hiv.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">38.</b-td>
                    <b-td>Kemungkinan Bukan Infeksi HIV</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'kemungkinan_bukan_infeksi_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'kemungkinan_bukan_infeksi_hiv')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.kemungkinan_bukan_infeksi_hiv.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">39.</b-td>
                    <b-td>Masalah Keluhan Lain</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'masalah_keluhan_lain')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'masalah_keluhan_lain')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.masalah_keluhan_lain.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="text-center">40.</b-td>
                    <b-td>Jumlah Rujukan</b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.l"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'jumlah_rujukan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.p"
                        type="number"
                        @input="hitungTotal('klasifikasi_2_bulan_5_tahun', 'jumlah_rujukan')"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <b-form-input
                        v-model="is_data.klasifikasi_2_bulan_5_tahun.jumlah_rujukan.total"
                        readonly
                        type="number"
                      ></b-form-input>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button variant="secondary" @click="batalSimpan()">Batal</b-button>
              <b-button class="ml-2" variant="primary" @click="simpanData()">Simpan</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect,
  },
  props: {
    laporan_mtbs_id: {
      type: String,
      default() {
        return "";
      },
    },
    data_rekap: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      options_kecamatan: [],
      options_kelurahan: [],
      options_diagnosa: [],
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        bulan_awal: null,
        bulan_akhir: null,
        tahun_awal: "",
        tahun_akhir: "",
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      },
      is_data: {
        tahun_laporan_mtbs: 0 || Number(this.$moment().format("YYYY")),
        bulan_laporan_mtbs: 0 || Number(this.$moment().format("MM")),
        kegiatan_laporan_mtbm: {
          jumlah_bayi_sehat_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sehat_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_mendapat_mtbs_mtbm: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_sesuai_tempat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_dalam_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_luar_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        klasifikasi_0_bulan_2_bulan: {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_infeksi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_bakteri_lokal: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_infeksi: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_menurut_umur: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_tidak_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        klasifikasi_2_bulan_5_tahun: {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_sangat_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          batuk_bukan_pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten: {
            l: 0,
            p: 0,
            total: 0
          },
          disentri: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_berat_dengan_demam: {
            l: 0,
            p: 0,
            total: 0
          },
          malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mulut: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_mulut_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_berdarah_dengue: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          mastoiditis: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_akut: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_kronis: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_infeksi_telinga: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_dengan_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_tanpa_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_kurang: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_baik: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diduga_terinfeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          kemungkinan_bukan_infeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        },
        jumlah_pustu: 0,
        jumlah_pustu_lapor: 0,
        jumlah_poskesdes: 0,
        jumlah_poskesdes_lapor: 0,

        nama_puskesmas: "",
        kode_puskesmas: "",
      },
      items: [],
      range_tanggal: "",
      sub_total_harga: 0,

      checked: false,
      checked_poli: "",
      select_puskesmas: [],
      select_poli: [],
      options_puskesmas: [],
      options_poli: [],
      
      optionBulan: [
        { text: "-- pilih bulan --", value: null },
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maret", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Agustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "Desember", value: 12 },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  activated() {
    this.data_search.tanggal_awal = new Date();
    this.data_search.tanggal_akhir = new Date();
    this.getData();
    this.getDataKunjungan();
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      tahun_laporan_mtbs: {},
      bulan_laporan_mtbs: {},
      kegiatan_laporan_mtbm: {},
      klasifikasi_2_bulan_5_tahun: {},
      klasifikasi_0_bulan_2_bulan: {},
      jumlah_pustu: {},
      jumlah_pustu_lapor: {},
      jumlah_poskesdes: {},
      jumlah_poskesdes_lapor: {},

      nama_puskesmas: {},
      kode_puskesmas: {},
    },
  },
  mounted() {
    // console.log("input", this.$store.state.puskesmas);
    // this.is_data.nama_puskesmas = this.$store.state.puskesmas.nama_puskesmas;
    // this.is_data.kode_puskesmas = this.$store.state.puskesmas.kode_puskesmas;
    this.is_data = this.data_rekap
    console.log("mounted", this.is_data)
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      let _puskesmas = { nama_puskesmas: vm.$store.state.puskesmas.nama_puskesmas };
      let puskesmas = await vm.$axios.post("/puskesmas/list", _puskesmas);
      if (puskesmas.data.data.length) {
        vm.options_puskesmas = puskesmas.data.data;
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
          let x = vm.options_puskesmas[i];
          x.checked = false;
        }
      }
      let _poli = { nama_poli: "kia" };
      let poli = await vm.$axios.post("/ms_poli/list", _poli);
      if (poli.data.data.length) {
        vm.options_poli = poli.data.data;
        for (let i = 0; i < vm.options_poli.length; i++) {
          let x = vm.options_poli[i];
          x.checked = false;
        }
      }
    },
    getDataKunjungan() {
      let vm = this;
      if (vm.select_puskesmas.length) {
        vm.data_search.bulk_puskesmas_id = vm.select_puskesmas;
      }
      if (vm.select_poli.length) {
        vm.data_search.bulk_ms_poli_id = vm.select_poli;
      }
      vm.resetIsData()
      // console.log("data search", vm.data_search);
      // vm.range_tanggal = `${vm .$moment(vm.data_search.tanggal_awal).format("MMMM YYYY")} - ${vm.$moment(vm.data_search.tanggal_akhir).format("MMMM YYYY")}`;

      vm.data_search.bulan_laporan_mtbs = vm.is_data.bulan_laporan_mtbs
      vm.data_search.tahun_laporan_mtbs = vm.is_data.tahun_laporan_mtbs

      console.log(vm.data_search)

      vm.$axios
        .post("/laporan_mtbs/list", vm.data_search)
        .then((res) => {
          // console.log(res, "ini respon");
          if (res.data.status == 200) {
            let x = res.data.data;
            vm.items = x;
            if (x.length) {
              vm.is_data = x[0]
            } else {
              vm.is_data.bulan_laporan_mtbs = vm.data_search.bulan_laporan_mtbs
              vm.is_data.tahun_laporan_mtbs = vm.data_search.tahun_laporan_mtbs
              vm.is_data.nama_puskesmas = vm.$store.state.puskesmas.nama_puskesmas;
              vm.is_data.kode_puskesmas = vm.$store.state.puskesmas.kode_puskesmas;
            }
            console.log(">>>>>>>>>>>", vm.is_data);
          } else {
            vm.items = [];
            vm.$store.commit("set_alert", {
              msg: "TIDAK ADA DATA",
              variant: "danger",
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$store.commit("set_alert", {
            msg: err.message,
            variant: "danger",
            showing: true,
          });
        });
    },
    toggleSelect: function () {
      let select = this.checked;
      this.options_puskesmas.forEach(function (data) {
        data.checked = select;
      });
      this.select_puskesmas = [];
      for (let i = 0; i < this.options_puskesmas.length; i++) {
        let x = this.options_puskesmas[i];
        if (x.checked == true) {
          this.select_puskesmas.push(x.id);
        }
      }
      this.$forceUpdate();
    },
    toggleSelectPoli: function () {
      let select = this.checked_poli;
      // console.log(this.checked_poli);
      this.options_poli.forEach(function (data) {
        data.checked = select;
      });
      this.select_poli = [];
      for (let i = 0; i < this.options_poli.length; i++) {
        let x = this.options_poli[i];
        if (x.checked == true) {
          this.select_poli.push(x.ms_poli_id);
        }
      }
      this.$forceUpdate();
    },
    addSelect() {
      let vm = this;
      vm.select_puskesmas = [];
      for (let i = 0; i < vm.options_puskesmas.length; i++) {
        let x = vm.options_puskesmas[i];
        if (x.checked == true) {
          vm.select_puskesmas.push(x.id);
        }
      }
    },
    addSelectPoli() {
      let vm = this;
      vm.select_poli = [];
      for (let i = 0; i < vm.options_poli.length; i++) {
        let x = vm.options_poli[i];
        if (x.checked == true) {
          vm.select_poli.push(x.ms_poli_id);
        }
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
    resetDataKunjungan() {
      this.data_search = {
        tanggal_awal: new Date(),
        tanggal_akhir: new Date(),
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      };
      this.select_puskesmas = [];
      this.select_poli = [];
      this.getData();
      this.getDataKunjungan();
    },
    setTanggal() {
      this.data_search.tanggal_awal = new Date();
      this.data_search.tanggal_akhir = new Date();
    },
    async registerDataRekapitulasi() {
      const vm = this
      let ep = 'register'
      if (vm.laporan_mtbs_id) {
        ep = "update"
        vm.is_data.id = vm.laporan_mtbs_id
      }
      try {
        let res = await vm.$axios.post(`/laporan_mtbs/${ep}`, vm.is_data)
        console.log(res)
        if (res.data.status == 200 && res.data.message == 'sukses') {
          this.$emit('emitInputDataRekap')
        }
      } catch (err) {
        console.log(err)
      }
    },
    batalSimpan() {
      this.$bvModal.msgBoxConfirm('Kembali dan batal simpan data?')
        .then(value => {
          if (value) {
            this.$emit('emitInputDataRekap')
          } else {
            console.log("gajadi")
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    simpanData() {
      this.$bvModal.msgBoxConfirm('Simpan data rekapitulasi?')
        .then(value => {
          if (value) {
            this.registerDataRekapitulasi()
          } else {
            console.log("gajadi")
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    hitungTotal(key1, key2) {
      this.is_data[key1][key2].total = Number(this.is_data[key1][key2].l) + Number(this.is_data[key1][key2].p)
    },
    totalBayiPelayananMTBS() {
      let parentKey1 = 'kegiatan_laporan_mtbm'
      let parentKey2 = 'jumlah_bayi_mendapat_mtbs_mtbm'

      let childKey1 = 'jumlah_0_2_bulan_sehat'
      let childKey2 = 'jumlah_0_2_bulan_sakit'
      let childKey3 = 'jumlah_2_5_bulan_sehat'
      let childKey4 = 'jumlah_2_5_bulan_sakit'
      this.is_data[parentKey1][parentKey2].l = +this.is_data[parentKey1][childKey1].l + +this.is_data[parentKey1][childKey2].l + +this.is_data[parentKey1][childKey3].l + +this.is_data[parentKey1][childKey4].l
      this.is_data[parentKey1][parentKey2].p = +this.is_data[parentKey1][childKey1].p + +this.is_data[parentKey1][childKey2].p + +this.is_data[parentKey1][childKey3].p + +this.is_data[parentKey1][childKey4].p
      this.is_data[parentKey1][parentKey2].total = +this.is_data[parentKey1][parentKey2].l + +this.is_data[parentKey1][parentKey2].p
    },
    totalKunjungan() {
      let parentKey1 = 'kegiatan_laporan_mtbm'
      let parentKey2 = 'jumlah_sesuai_tempat'

      let childKey1 = 'jumlah_dalam_wilayah'
      let childKey2 = 'jumlah_luar_wilayah'
      this.is_data[parentKey1][parentKey2].l = +this.is_data[parentKey1][childKey1].l + +this.is_data[parentKey1][childKey2].l
      this.is_data[parentKey1][parentKey2].p = +this.is_data[parentKey1][childKey1].p + +this.is_data[parentKey1][childKey2].p
      this.is_data[parentKey1][parentKey2].total = +this.is_data[parentKey1][parentKey2].l + +this.is_data[parentKey1][parentKey2].p
    },
    resetIsData() {
        // tahun_laporan_mtbs: 0 || Number(this.$moment().format("YYYY")),
        // bulan_laporan_mtbs: 0 || Number(this.$moment().format("MM")),
      this.is_data.kegiatan_laporan_mtbm = {
          jumlah_bayi_sehat_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_0_2_bulan: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sehat_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_sakit_2_bulan_5_tahun: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_bayi_mendapat_mtbs_mtbm: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_0_2_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sehat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_2_5_bulan_sakit: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_sesuai_tempat: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_dalam_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_luar_wilayah: {
            l: 0,
            p: 0,
            total: 0
          },
        }
      this.is_data.klasifikasi_0_bulan_2_bulan = {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_infeksi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_bakteri_lokal: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_infeksi: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_ikterus: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_bukan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_menurut_umur: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          bb_rendah_tidak_masalah_pemberian_asi: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        }
      this.is_data.klasifikasi_2_bulan_5_tahun = {
          jumlah_kunjungan_ulang: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_sangat_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          batuk_bukan_pneumonia: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_dehidrasi_ringan: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_tanpa_dehidrasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          diare_persisten: {
            l: 0,
            p: 0,
            total: 0
          },
          disentri: {
            l: 0,
            p: 0,
            total: 0
          },
          penyakit_berat_dengan_demam: {
            l: 0,
            p: 0,
            total: 0
          },
          malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_bukan_malaria: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mulut: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_pada_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak_dengan_komplikasi_mulut_mata: {
            l: 0,
            p: 0,
            total: 0
          },
          campak: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_berdarah_dengue: {
            l: 0,
            p: 0,
            total: 0
          },
          mungkin_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          demam_mungkin_bukan_dbd: {
            l: 0,
            p: 0,
            total: 0
          },
          mastoiditis: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_akut: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_telinga_kronis: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_ada_infeksi_telinga: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_dengan_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_buruk_tanpa_komplikasi: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_kurang: {
            l: 0,
            p: 0,
            total: 0
          },
          gizi_baik: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia_berat: {
            l: 0,
            p: 0,
            total: 0
          },
          anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          tidak_anemia: {
            l: 0,
            p: 0,
            total: 0
          },
          infeksi_hiv_terkonfirmasi: {
            l: 0,
            p: 0,
            total: 0
          },
          diduga_terinfeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          terpajan_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          kemungkinan_bukan_infeksi_hiv: {
            l: 0,
            p: 0,
            total: 0
          },
          masalah_keluhan_lain: {
            l: 0,
            p: 0,
            total: 0
          },
          jumlah_rujukan: {
            l: 0,
            p: 0,
            total: 0
          },
        }
      this.is_data.jumlah_pustu = 0
      this.is_data.jumlah_pustu_lapor = 0
      this.is_data.jumlah_poskesdes = 0
      this.is_data.jumlah_poskesdes_lapor = 0

      this.is_data.nama_puskesmas = ""
      this.is_data.kode_puskesmas = ""
    },
  },
};
</script>

<style>
/* #kohort-kia .custom-table td {
  padding: 0 !important;
  font-size: 14px;
  font-weight: bold;
  border: hidden !important;
}

#kohort-kia .custom-control-label {
  padding-top: 2.5px;
}
.top-text-variant {
  font-size: 10px;
} */


#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th {
  background-color: #D52F65 !important ;
  color: #fff !important;
}
#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th:first-child {
  border-top-left-radius: 8px;
}
#rekapitulasi_mtbs_mtbm .warna-rekapitulasi-mtbs th:last-child {
  border-top-right-radius: 8px;
}
.warna-rekapitulasi-td {
  background-color: #D52F65 !important ;
  color: #fff !important;
  font-weight: 700
}
</style>
